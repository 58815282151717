import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Quote from "../components/quote"
import Form from "../components/form"

const ContactPage = () => {
  return (
    <React.Fragment>
      <Layout>
        <Seo title="Orçamento" />
        <Container className="padding-top padding-bottom">
          <Row className="title half-padding-bottom text-center justify-content-center">
            <Col md={10}>
              <h1>Faça seu orçamento</h1>
              <p>
                <strong>
                  Preencha seus dados para economizar até 95% na conta de
                  energia!
                </strong>
              </p>
              <p>
                Digite seus dados abaixo e nos diga o que procura que entraremos
                em contato para tirar suas dúvidas, questionamentos e realizar
                seu orçamento.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="quote-border">
              <Row className="justify-content-center">
                <Col md={12}>
                  <Quote />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="bg-light-grey">
          <Container className="padding-top padding-bottom">
            <Row className="title half-padding-bottom text-center justify-content-center">
              <Col md={10}>
                <h1>Quero mais informações</h1>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={10}>
                <Form />
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default ContactPage
