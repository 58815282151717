import React from "react"
import { Row, Col, Button, Form } from "react-bootstrap"
import { useFormik } from "formik"
import InputMask from "react-input-mask"

const QuoteComponent = () => {
  const validate = values => {
    const errors = {}

    if (!values.name) {
      errors.name = "Campo obrigatório"
    }

    if (!values.phone) {
      errors.phone = "Campo obrigatório"
    }

    if (!values.email) {
      errors.email = "Campo obrigatório"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Digite um email válido"
    }

    if (!values.city) {
      errors.city = "Campo obrigatório"
    }

    if (!values.consumption) {
      errors.consumption = "Campo obrigatório"
    }

    if (!values.type) {
      errors.type = "Campo obrigatório"
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      city: "",
      type: "",
      consumption: "",
    },
    validate,
    onSubmit: values => {
      fetch(`${process.env.API_URL || "http://localhost:1337"}/quotes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then(response => {
          if (!response.ok) throw response
        })
        .then(result => alert("Mensagem enviada com sucesso!"))
        .catch(error => {
          alert("Houve um erro, tente novamente ou nos chame no Whatsapp!")
          formik.setSubmitting(false)
        })
    },
  })

  React.useEffect(() => {
    console.log(formik.values.consumption)
    console.log(formik.values.type)
    if (formik.values.consumption === "") {
      document.querySelector("select[name=consumption]").classList.add("greyed")
    } else {
      document
        .querySelector("select[name=consumption]")
        .classList.remove("greyed")
    }
    if (formik.values.type === "") {
      document.querySelector("select[name=type]").classList.add("greyed")
    } else {
      document.querySelector("select[name=type]").classList.remove("greyed")
    }
  }, [formik])

  return (
    <Row>
      <Col className="form-holder">
        <Form onSubmit={formik.handleSubmit} className="quote-form">
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Nome completo"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div>
                    <small>{formik.errors.name}</small>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  type="email"
                  placeholder="E-mail"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div>
                    <small>{formik.errors.email}</small>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group controlId="phone">
                <InputMask
                  type="text"
                  placeholder="DDD + Telefone"
                  name="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  mask="(99) 9 9999-9999"
                  maskChar=" "
                  className="form-control"
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div>
                    <small>{formik.errors.phone}</small>
                  </div>
                ) : null}
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="CEP / Cidade"
                  name="city"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                />
                {formik.touched.city && formik.errors.city ? (
                  <div>
                    <small>{formik.errors.city}</small>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Control
                  as="select"
                  name="type"
                  defaultValue="Qual tipo de projeto do seu interesse?"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.type}
                  // defaultValue={formik.values.type}
                >
                  <option value="" disabled>
                    Qual tipo de projeto do seu interesse?
                  </option>
                  <option value="Comercial">Comercial</option>
                  <option value="Industrial">Industrial</option>
                  <option value="Residencial">Residencial</option>
                  <option value="Rural">Rural</option>
                </Form.Control>
                {formik.touched.type && formik.errors.type ? (
                  <div>
                    <small>{formik.errors.type}</small>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  as="select"
                  name="consumption"
                  defaultValue=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.consumption}
                  // defaultValue={formik.values.consumption}
                >
                  <option value="" disabled>
                    Consumo mensal de energia
                  </option>
                  <option value="até 1.000kWh">até 1.000kWh</option>
                  <option value="1.000kWh ou mais">1.000kWh ou mais</option>
                </Form.Control>
                {formik.touched.consumption && formik.errors.consumption ? (
                  <div>
                    <small>{formik.errors.consumption}</small>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group className="mt-2 text-right mb-0">
                <Button
                  variant="primary"
                  type="submit"
                  size="md"
                  disabled={
                    !(formik.isValid && formik.dirty) || formik.isSubmitting
                  }
                >
                  Enviar
                </Button>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </Col>
    </Row>
  )
}

export default QuoteComponent
